import { Box, styled } from "@mui/material";
import React from "react";

export default function Page404() {
  return <Wrap>404</Wrap>;
}

const Wrap = styled(Box)(() => ({
  width: "100%",
  minHeight: "calc(100vh - 72px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
